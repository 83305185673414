import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Styles
import {
  InterludeImage,
  InterludeSection,
  InterludeSectionInner,
  InterludeText,
} from "../styles/interludeStyles"
import { Container } from "../styles/globalStyles"

const Interlude = ({ images, description, layout }) => {
  return (
    <InterludeSection>
      <Container>
        <InterludeSectionInner layout={layout}>
          {images.map((image, i) => (
            <InterludeImage key={i} order={i}>
              <GatsbyImage image={getImage(image)} alt={image.description} />
            </InterludeImage>
          ))}
          <InterludeText>
            <p>{description}</p>
          </InterludeText>
        </InterludeSectionInner>
      </Container>
    </InterludeSection>
  )
}

export default Interlude
