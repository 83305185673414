import styled, { css } from "styled-components"
import { Grid } from "./globalStyles"

export const InterludeSection = styled.section`
  padding-block: var(--space-m);
`

export const InterludeSectionInner = styled.div`
  display: flex;
  flex-direction: column;

  gap: var(--leading);

  /* Tablet Adjustments */
  @media (min-width: 680px) {
    ${Grid}
  }

  /* Desktop Adjustments */
  @media (min-width: 1200px) {
    ${({ layout }) =>
      (layout === "cover" &&
        css`
          grid-template-areas:
            "i i i i i i i i i i i i"
            ". . . . . . t t t t . .";
          ${InterludeImage} {
            height: calc(var(--leading) * 27);
          }
        `) ||
      (layout === "split" &&
        css`
          grid-template-areas:
            ". i i i i . d d d d d ."
            ". i i i i . t t t t . .";
          ${InterludeText} {
            align-self: end;
          }
          ${InterludeImage} {
            height: calc(var(--leading) * 24);
          }
        `)}
  }
`

export const InterludeImage = styled.div`
  .gatsby-image-wrapper {
    aspect-ratio: 1/1;
  }

  /* Tablet Adjustments */
  @media (min-width: 680px) {
    grid-column: 1 / -1;

    .gatsby-image-wrapper {
      aspect-ratio: auto;
      height: 100%;
    }
  }

  /* Desktop Adjustments */
  @media (min-width: 1200px) {
    ${({ order }) =>
      (order === 0 &&
        css`
          grid-area: i;
        `) ||
      (order === 1 &&
        css`
          grid-area: d;
          height: calc(var(--leading) * 12) !important;
        `)}
  }
`

export const InterludeText = styled.div`
  /* Tablet Adjustments */
  @media (min-width: 680px) {
    grid-column: 2 / span 4;
    padding-inline: var(--space-p);

    .gatsby-image-wrapper {
      aspect-ratio: auto;
      height: 100%;
    }
  }

  /* Desktop Adjustments */
  @media (min-width: 1200px) {
    grid-area: t;
  }
`
